<template>
  <div class="padding-top-10 contain_pay">
    <top :bgWhite="true"></top>
    <div class="content">
        <div class="item">
            <div>
                <div >快递单号</div>
                <div style="margin-top:24px;">快递公司</div>
                <div style="margin-top:24px;">邮寄材料内容描述</div>
            </div>
            <div style="text-align:right">
                <div>{{ data.LogisticCode }}</div>
                <div style="margin-top:24px;">{{ data.ShipperCode }}</div>
                <div style="margin-top:24px;">{{ data.express_suggest }}</div>
            </div>
        </div>
        <div class="wlbox">
          <el-steps direction="vertical" finish-status="success" :active="1" space="80px" >
              <el-step  :title="item.AcceptStation" v-for="(item,index) in data.Traces" :key="index" :description="item.AcceptTime">
                 <i class="stepicon" slot="icon"></i>
              </el-step>
          </el-steps>
        </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import payCode from "@/components/pay-code/pay-code.vue";
import top from "@/components/top";
import bottom from "@/components/bottom";
export default {
  name: "authen",
  components: {
    bottom,
    top,
    payCode,
  },
  data() {
    return {
      url: "", //
      timer: null,
      activeName: "0",
      page:1 , 
      totalSize:0,//总页数
      type:'',
      data:{},
      isInit:false,
      currentIndex: 0, //选择的索引
      aid: "",
    };
  },
  mounted() {
       this.aid = this.$route.query.aid;
    this.loadData();
  },
  methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
    loadData(){
         this.ajax('post', '/v1/63c4c290210f8', {
                      aid:this.aid
				  },res => {
                      this.isInit = true;
                        this.data =  res.data   
                        console.log( res.data.data)             
                  })
    },
    //去详情
    todetail(item){
        this.$router.push({
							path: '/express-detail',
							query:{
								id:item.order_sn,
							}
						})
    },
    	// 页数改变
			handleSizeChange(e){
				console.log(e);
				this.page = e;
				this.loadData();
				target_top.scrollIntoView();
            },
            // 跳页
			handleCurrentChange(e){
				console.log(e);
				this.page = e;
				this.loadData();
				target_top.scrollIntoView();
			},
  },
};
</script>

<style scoped>
.contain_pay {
  width: 100vw;
  height: 100vh;
  background-color: #F6F7F9;
  overflow: auto;
}
.content {
  width: 40%;
  height: auto;
  margin: 80px  auto;
}
.item{
    background: #fff;
    padding: 24px;
    margin-bottom: 36px;
    border-radius: 16px;
    width: 100;
    display: flex;
    justify-content: space-between;
    font-size: 14px;

}
.wlbox{
     background: #fff;
    padding: 24px;
    margin-bottom: 48px;
    width: 100;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}
.stepicon{
  width: 22px;
  height: 22px;
  background: #999;
  border-radius: 50%;
}
</style>
